import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-styled-flexboxgrid'
import styled, { ThemeProvider } from 'styled-components'
import Header from '../Header'
import Navigation from '../Navigation'
import Footer from '../Footer'
import theme from '../../utils/theme.js'

const propTypes = {
  children: PropTypes.func.isRequired,
}

const Layout = ({ children }) => (
  <Row>
    <Col xs={12}>
      <Header />
      <Navigation />
      {children}
      <Footer />
    </Col>
  </Row>
)
const MainLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Layout>{children}</Layout>
  </ThemeProvider>
)

MainLayout.propTypes = propTypes

export default MainLayout
