import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const SectionContainer = styled.section`
  max-width: 940px;
  display: block;
  margin: 0 auto;
`

const Container = props => {
  const { children } = props
  return <SectionContainer>{children}</SectionContainer>
}

Container.propTypes = propTypes

export default Container
