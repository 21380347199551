import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import Container from '../Container'
import LogoImg from '../../images/logo.svg'

import { calculateRem } from '../../utils/javascript-styles'

const StyledHeader = styled.header`
  padding: 1rem;
`
const Logo = styled.img`
  height: ${calculateRem(150)};
  margin-bottom: 0;
`
const Headline = styled.h3`
  text-align: right;
  color: ${props => props.theme.portGore};
  margin-bottom: 0;
`
const Address = styled.p`
  color: ${props => props.theme.jewel};
  text-align: right;
  margin-bottom: 0;
`
const Header = () => {
  return (
    <StyledHeader>
      <Container>
        <Row>
          <Col xs={6}>
            <Logo src={LogoImg} alt="Logo" />
          </Col>
          <Col xs={6}>
            <Headline>Call us Today: 480-327-6835</Headline>
            <Address>6424 E Greenway Pkwy Suite 100</Address>
            <Address>Scottsdale, AZ 85254</Address>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  )
}

export default Header
