import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { rem } from 'polished'

const StyledNavSection = styled.section`
  border-top: 1px solid ${({ theme }) => theme.mercury};
`

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const NavItem = styled.button`
  color: ${props => props.theme.portGore};
  background-color: transparent;
  border: 0;
  padding: 2rem;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.denim};
  }
  &.active {
    border-bottom: 4px solid ${({ theme }) => theme.jewel};
  }
`

const StyledDDContainer = styled.div`
  position: relative;
`
const StyledDDCss = css`
  position: absolute;
  top: ${rem(100)};
  z-index: 1001;
  background-color: white;
  box-shadow: rgb(0 0 0 / 30%) 1px 1px 6px 2px;
  border-radius: 2px;
  width: 175%;
  &:after {
    bottom: 100%;
    left: 15%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
  }
`
const StyledDD = styled.div`
  ${props => (props.hasMenu ? StyledDDCss : ``)}
`

const StyledDDElement = styled(Link)`
  padding: ${rem(16)};
  display: block;
  color: ${({ theme }) => theme.portGore};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.denim};
  }
`

export {
  StyledNavSection,
  NavContainer,
  NavItem,
  StyledDDContainer,
  StyledDD,
  StyledDDElement,
}
