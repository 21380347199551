module.exports = {
  portGore: '#231f54',
  jewel: '#128142',
  denim: '#0864a1',
  supernova: '#FFCB05',
  atlantis: '#AECF37',
  white: '#ffffff',
  black: '#000000',
  oil: '#222',
  transparent: 'rgba(0,0,0,0)',
  mercury: '#e6e6e6',
  atoll: '#0c4a7b',
}
