import React from 'react'

import { navigate } from 'gatsby'
import Container from '../Container'

import {
  StyledNavSection,
  NavContainer,
  NavItem,
  StyledDDContainer,
  StyledDD,
  StyledDDElement,
} from './Navigation.styled'

const NavObj = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About Us',
    url: '/about-us',
  },
  {
    title: 'Packages',
    url: '/packages',
  },
  {
    title: 'Podcast',
    url: '/podcast',
  },
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'Partnerships',
    url: '/partnerships',
  },
]

const Navigation = () => (
  <StyledNavSection>
    <Container>
      <NavContainer>
        {NavObj.map(item => (
          <NavItem
            activeClassName="active"
            onClick={() => navigate(`${item.url}`)}
          >
            {item.title}
          </NavItem>
        ))}
      </NavContainer>
    </Container>
  </StyledNavSection>
)

export default Navigation
